import { z } from 'zod';
import { User } from 'shared/src/user-types';
import { DiscoveredMediaBuy, MediaBuy, SortDirection } from 'shared/src/media-buy-types';
import {
  ChannelSelection,
  Platform,
  TacticSelection,
  UnitPriceType
} from 'shared/src/line-item-channels';
import { omit } from 'lodash';

import { dateToUTC } from 'shared/src/zod-utils';
import { CombinedLineItem } from '@flights/frontend/src/store/strategy-combiner';
import { AgDateFilter, AgNumberFilter, AgSetFilter } from 'shared/src/ag-grid-filter-types';

const invalidPacingMessage = "Pacing details cannot be null if pacing_type is 'custom'";
const emptyValuesMessage = 'Cannot update to empty values';

export const PacingBlock = z.object({
  start_date: dateToUTC,
  end_date: dateToUTC,
  price: z.coerce.number()
});

export type PacingBlock = z.infer<typeof PacingBlock>;

export const PacingSchedule = z.object({
  blocks: z.array(PacingBlock)
});
export type PacingSchedule = z.infer<typeof PacingSchedule>;

export const PacingType = z.union([
  z.literal('lifetime'),
  z.literal('monthly'),
  z.literal('custom')
]);
export type PacingType = z.infer<typeof PacingType>;

export const LineItem = z.object({
  id: z.string(),
  strategy_id: z.string(),
  name: z.string(),
  channel: ChannelSelection,
  tactic: TacticSelection,
  unit_price_type: UnitPriceType,
  geo: z.string(),
  targeting: z.string(),
  description: z.string(),
  audience: z.string(),
  ad_formats: z.string(),
  price: z.coerce.number(),
  pacing_type: PacingType,
  pacing_details: z.union([PacingSchedule, z.null()]),
  unit_price: z.coerce.number(),
  target_margin: z.coerce.number(),
  start_date: dateToUTC,
  end_date: dateToUTC,
  media_platforms: z.array(Platform),
  media_traders: z.array(User),
  media_buys: z.array(MediaBuy),
  is_deleted: z.boolean()
});

export type LineItem = z.infer<typeof LineItem>;

export const DiscoveredLineItem = z.object({
  id: z.string(),
  media_campaign_id: z.string(),
  name: z.string(),
  start_date: dateToUTC,
  end_date: dateToUTC,
  channel: z.string(),
  tactic: z.string(),
  unit_price_type: z.string(),
  retail_price: z.coerce.number().optional(),
  target_margin: z.coerce.number(),
  geo: z.string().optional(),
  targeting: z.string().optional(),
  audience: z.string().optional(),
  pacing_type: PacingType,
  unit_price: z.coerce.number(),
  media_trader_id: z.string(),
  media_buys: z.array(DiscoveredMediaBuy)
});

export type DiscoveredLineItem = z.infer<typeof DiscoveredLineItem>;

export const PartialLineItem = LineItem.partial()
  .omit({ id: true, strategy_id: true, media_buys: true })
  .extend({ id: z.string(), strategy_id: z.string() });
export type PartialLineItem = z.infer<typeof PartialLineItem>;

export const NewLineItemDraft = z.object({
  type: z.literal('new'),
  data: PartialLineItem
});
export type NewLineItemDraft = z.infer<typeof NewLineItemDraft>;

export const UpdatedLineItemDraft = z.object({
  type: z.literal('update'),
  data: PartialLineItem
});
export type UpdatedLineItemDraft = z.infer<typeof UpdatedLineItemDraft>;

export const FinalisedNewLineItem = z.object({
  type: z.literal('new'),
  data: LineItem.omit({ media_buys: true })
    .refine(noEmptyValues, { message: emptyValuesMessage })
    .refine(newPacingIsValid, { message: invalidPacingMessage })
});
export type FinalisedNewLineItem = z.infer<typeof FinalisedNewLineItem>;

export const FinalisedLineItemUpdate = z.object({
  type: z.literal('update'),
  data: PartialLineItem.refine(updatedPacingIsValid, { message: invalidPacingMessage }).refine(
    noEmptyValues,
    { message: emptyValuesMessage }
  )
});
export type FinalisedLineItemUpdate = z.infer<typeof FinalisedLineItemUpdate>;

export const LineItemSortColumn = z.union([
  z.literal('name'),
  z.literal('campaign_id'),
  z.literal('campaign_name'),
  z.literal('channel'),
  z.literal('tactic'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('price'),
  z.literal('media_budget'),
  z.literal('estimated_units'),
  z.literal('unit_cost'),
  z.literal('target_margin')
]);
export type LineItemSortColumn = z.infer<typeof LineItemSortColumn>;
export const lineItemSortColumns = LineItemSortColumn.options.map(option => option.value);

export const LineItemListRow = LineItem.omit({ media_buys: true }).extend({
  campaign_id: z.string(),
  campaign_name: z.string(),
  num_media_buys: z.number(),
  media_budget: z.number()
});
export type LineItemListRow = z.infer<typeof LineItemListRow>;

export const LineItemSort = z.object({
  colId: LineItemSortColumn,
  sort: SortDirection
});
export type LineItemSort = z.infer<typeof LineItemSort>;

export const LineItemsFilterTypes = z.object({
  price: AgNumberFilter.optional(),
  media_budget: AgNumberFilter.optional(),
  channel: AgSetFilter.optional(),
  tactic: AgSetFilter.optional(),
  pacing_type: AgSetFilter.optional(),
  unit_price_type: AgSetFilter.optional(),
  start_date: AgDateFilter.optional(),
  end_date: AgDateFilter.optional()
});
export type LineItemsFilterTypes = z.infer<typeof LineItemsFilterTypes>;

export const LineItemListRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  sort: LineItemSort.optional(),
  filters: LineItemsFilterTypes
});

export type LineItemListRequest = z.infer<typeof LineItemListRequest>;

export const LineItemListUrlParams = LineItemListRequest.omit({ page: true, search: true });
export type LineItemListUrlParams = z.infer<typeof LineItemListUrlParams>;

export const LineItemListResult = z.object({
  line_items: z.array(LineItemListRow),
  total: z.number()
});

export type LineItemListResult = z.infer<typeof LineItemListResult>;

// TODO[mk] - what happens when we change the channel, which nulls some other fields?
export const FinalisedLineItemChange = z.union([FinalisedNewLineItem, FinalisedLineItemUpdate]);
export type FinalisedLineItemChange = z.infer<typeof FinalisedLineItemChange>;

// Draft is not needed for line item updates
export const LineItemChangeDraft = z.union([NewLineItemDraft, UpdatedLineItemDraft]);
export type LineItemChangeDraft = z.infer<typeof LineItemChangeDraft>;

function newPacingIsValid({ pacing_type, pacing_details }: PartialLineItem) {
  return pacing_type === 'custom' ? pacing_details != null : pacing_details == null;
}

export function updatedPacingIsValid({ pacing_type, pacing_details }: PartialLineItem) {
  if (!pacing_type) return true;
  return (pacing_type === 'custom' && pacing_details != null) || pacing_type !== 'custom';
}

export function noEmptyValues(lineItem: PartialLineItem) {
  return Object.values(omit(lineItem, 'pacing_details')).every(value => value != null);
}

export function isCompleted(combinedLineItem: CombinedLineItem) {
  return LineItem.safeParse(combinedLineItem).success;
}

export function convertToLineItem(combinedLineItem: CombinedLineItem) {
  return LineItem.parse(combinedLineItem);
}
